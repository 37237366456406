import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import useAxios from "../../api/useAxios";
import CustomPaginatedSearchTable from "../../components/table/custom-paginated-search-table.component";
import MaterialReactTable from "material-react-table";


const TrainingRequests = () => {
  document.title = "طلبات التدريب | صناع السعادة";

  const api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`training-requests`, async () => api.get(`/courses/group-registrations?page=${pagination.pageIndex}&page_size=${pagination.pageSize}`), {
    cacheTime: 0,
  });

  const trainingRequests = response?.data ?? [];
  const totalCount = response?.data?.count

  const [search, setSearch] = useState("");

  const [pagination, setPagination] =
    useState({
      pageIndex: 0,
      pageSize: 10,
    });

// Refetching when search text change
  useEffect(() => {
    if (search != "" && search) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
    if (pagination.pageIndex == 0) {
      refetch();
    }
  }, [search]);

  //Refetching when page change
  useEffect(() => {
    console.log('pagesize', pagination.pageSize)
    console.log('pageIndex', pagination.pageIndex)
    refetch();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  const columns = [
    {
        header: "اسم الدورة المختارة",
        accessorKey: "schedule",
    },

    {
      header: "ممثل الشركة",
      accessorKey: "company_representative",
    },

    {
      header: "اسم الشركة",
      accessorKey: "company_name",
    },

    {
      header: "قطاع الشركة",
      accessorKey: "company_sector",
    },
    
    {
      header: "المسمى الوظيفي",
      accessorKey: "job_title",
    },
    
    {
      header: "رقم التواصل",
      accessorKey: "phone",
    },
    {
      header: "البريد الإلكتروني",
      accessorKey: "email",
    },
    {
      header: "عدد المتدربين",
      accessorKey: "no_of_trainees",
    },
    {
      header: "اكسل",
      Cell: ({ row }) => (
          <a href={row.original.trainees_file}>Download</a>
      ),
    },
   
  ];

  return (
    <>
      <div className="container-fluid mt-4 mb-3">
        {/* container fluid */}
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page">
            طلبات التدريب
        </div>

        <MaterialReactTable
            columns={columns}
            isLoading={isLoading}
            data={trainingRequests}
            enableRowNumbers
        />

        {/* <CustomPaginatedSearchTable
            data={trainingRequests}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            setPagination={setPagination}
            rowCount={totalCount}
            initialState={pagination}
            search={search}
            setSearch={setSearch} 
          />
            */}
    
      </div>
    </>
  );
};

export default TrainingRequests;
