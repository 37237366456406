import SvgColor from "../../../theme-components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "لوحة القيادة",
    path: "/dashboard",
    icon: icon("ic_dashboard"),
  },

  {
    title: "إدارة الدورات",
    path: "/manage-courses",
    icon: icon("ic_book"),
  },

  {
    title: "إدارة المواقع",
    path: "/manage-locations",
    icon: icon("ic_location"),
  },

  {
    title: "إدارة الجداول الزمنية",
    path: "/manage-schedules",
    icon: icon("ic_schedule"),
  },
  {
    title: "ادارة المستخدمين",
    path: "/manage-users",
    icon: icon("ic_users"),
  },

  {
    title: "إدارة الحجوزات",
    path: "/manage-bookings",
    icon: icon("ic_booking"),
  },

  {
    title: "إدارة المدربين",
    path: "/manage-trainers",
    icon: icon("ic_trainer"),
  },

  {
    title: "إدارة الاستبيانات",
    path: "/manage-evaluation",
    icon: icon("ic_questionnaire"),
  },
  
  
  {
    title: "إدارة الحضور",
    path: "/manage-attendance",
    icon: icon("ic_category"),
  },
  {
    title: "إدارة الإخطارات",
    path: "/manage-notifications",
    icon: icon("ic_sendEmail"),
  },
  {
    title: "إدارة التقييم",
    path: "/manage-questionnaires",
    icon: icon("ic_listing"),
  },
  {
    title: "طلبات التدريب",
    path: "/training-requests",
    icon: icon("ic_listing"),
  },
  
];

export default navConfig;
